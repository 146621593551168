exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-client-portal-js": () => import("./../../../src/pages/client-portal.js" /* webpackChunkName: "component---src-pages-client-portal-js" */),
  "component---src-pages-company-page-js": () => import("./../../../src/pages/company-page.js" /* webpackChunkName: "component---src-pages-company-page-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-out-tsx": () => import("./../../../src/pages/log-out.tsx" /* webpackChunkName: "component---src-pages-log-out-tsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-set-password-js": () => import("./../../../src/pages/set-password.js" /* webpackChunkName: "component---src-pages-set-password-js" */)
}

